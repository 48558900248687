import { Injectable } from '@angular/core';

import { Socket } from 'socket.io-client';

import { SocketService } from './socket.service';
import { UtilsService } from './utils.service';

@Injectable()
export class SocketResolverService  {
    constructor(
        private readonly socketService: SocketService,
        private readonly utilsService: UtilsService,
    ) {
    }

    // This resolver check if a valid socket connection exist
    // If it doesn't exist, create it
    // The page is load when a good socket connection was ended to created
    public resolve(): Promise<Socket> {
        return new Promise((resolve: any) => {
            if (!this.utilsService.onBrowser()) {
                resolve(null);
                return;
            }

            if (this.socketService.socketValid) {
                resolve(this.socketService.socket);
                return;
            }

            const socket = this.socketService.getSocket();
            socket.on('connect', () => {
                resolve(socket);
            });
        });
    }
}
