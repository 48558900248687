import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { io, Socket } from 'socket.io-client';


import { environment } from '../../../environments/environment';

import { UserTokenService } from './userToken.service';

@Injectable()
export class SocketService {
    public socket: Socket;
    public socketValid: boolean = false;

    constructor(
        private readonly userTokenService: UserTokenService,
        private readonly router: Router,
    ) {
    }

    public getSocket(): Socket {
        if (!this.socketValid) {
            this.socket = io(environment.socketUrl, {
                path: '',
                transports: ['websocket'],
                auth: {
                    authorization: localStorage.getItem('baragtools-user-token')
                },
                reconnectionAttempts: 1
            });
            this.socketValid = true;

            this.socket.on('Error', (error: any) => {
                if (error.status === 401 && error.message === 'Unauthorized') {
                    this.userTokenService.disconnectUser();
                    return;
                }

                if (error.status === 401 && error.message === 'Already connected') {
                    this.router.navigate(
                        ['/connexion'],
                        { queryParams: { error: 'already-connected' } }
                    ).then();

                    return;
                }

                console.log('ennd of the world');
            });

            this.socket.on('disconnect', () => {
                this.socketValid = false;
            });
        }

        console.log(this.socket);

        return this.socket;
    }
}
