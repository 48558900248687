import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IUserTokenObject } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class UserTokenService {
    constructor(private readonly router: Router) {
    }

    public parseUserToken(): IUserTokenObject {
        const token: any = localStorage.getItem('baragtools-user-token');
        return this.parseJwtToken(token);
    }

    public disconnectUser(): void {
        localStorage.removeItem('baragtools-connection-token');
        this.router.navigate(['/connexion']).then();
    }

    private parseJwtToken(token: string): IUserTokenObject {
        const base64Url: any = token.split('.')[1];
        const base64: any = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload: any = decodeURIComponent(atob(base64).split('').map((c: string) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}
