import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(
        private readonly http: HttpClient,
    ) {
    }

    /**
     * Do a GET call API to the api url set in the env file
     * @param type data
     * @param url (collection and parameters)
     * @param headers data
     * @param body data
     * @Return Observable with response of the request
     */
    public apiRequest(type: 'get' | 'post' | 'put' | 'delete', url: string, headers: any = { }, body?: any): Observable<any> {
        if (type === 'get' || type === 'delete') {
            // @ts-ignore
            return this.http[type](
                `${environment.apiUrl}/${url}`,
                {
                    headers: new HttpHeaders(
                        headers
                    )
                }
            );
        }

        return this.http[type](
            `${environment.apiUrl}/${url}`,
            body,
            {
                headers: new HttpHeaders(
                    headers
                )
            }
        );
    }
}
