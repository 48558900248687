import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {
    }

    /**
     * Check if the app is running on browser side and not on server side
     * Return True when the application is running on browser
     */
    public onBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }
}
